
import _ from 'lodash'
export function airTicketsFormat(formList) {
  var newList = _.cloneDeep(formList)
  newList.forEach(item => {
    item.dep_point_cd = item.dep_point_cd[1]
    item.arr_point_cd = item.arr_point_cd[1]
  })
  return newList
}

export function trnTicketsFormat(formList) {
  var newList = _.cloneDeep(formList)
  newList.forEach(item => {
    item.dep_point_cd = item.dep_point_cd[1]
    item.arr_point_cd = item.arr_point_cd[1]
  })
  return newList
}

export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
